body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: scale(1);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: scale(0.9);
}
